<template>
    <div class="row">
        <div class="col-12" v-if="!isLoad">
            <div class="card" v-if="point.content != ''">
                <div class="card-header pt-4 pl-3 m-0 bg-current">
                    <h3 class="card-title text-white"><i class="fas fa-plus mr-2"></i> Create Syllabus Unit</h3>
                </div>
                <div class="card-body">
                    <form @submit.prevent="patchPoint()" class="row">
                        <div class="form-group col-lg-6">
                            <label for="">Name</label>
                            <input type="text" v-model="point.content" class="form-control" placeholder="Insert Unit Name..." required>
                        </div>
                        <div class="form-group col-lg-6">
                            <label for="">Cycle</label>
                            <select v-model="point.cycle" class="form-control" required>
                                <option value="" selected disabled>-- Choose Cycle --</option>
                                <option value="1">Cycle 1</option>
                                <option value="2">Cycle 2</option>
                                <option value="3">Cycle 3</option>
                            </select>
                        </div>
                        <div class="form-group col-lg-12">
                            <label for="">Description</label>
                            <textarea v-model="point.description" rows="3" class="form-control" placeholder="Insert Unit Description..." required></textarea>
                        </div>
                        <div class="form-group col-12 text-right">
                            <label for="">&nbsp;</label>
                            <button type="submit" class="btn btn-current">Submit</button>
                        </div>
                    </form>
                </div>
            </div>
            <div v-else class="card">
                <div class="card-body">
                    <div class="row py-2">
                        <div class="col-12 text-center my-2">
                            <img :src="'/images/empty.png'" alt="" width="300">
                            <h1 class="text-muted">Data don't exist</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="col-12 text-center py-3">
            <div class="my-3">
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
                <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return{
            paramsId: this.$route.params.idCourse,
            idSyllabus: this.$route.params.idSyllabus,
            idPoint: this.$route.params.idPoint,
            isLoad: true,
            point: {
                content: '',
                cycle: '',
                description: '',
            }
        }
    },
    created(){
        this.getPoint()
    },
    methods: {
        async getPoint(){
            await axios.get(`${process.env.VUE_APP_URL_API}/admin/syllabus/point/detail?id=${this.idPoint}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.isLoad = false
                this.point = res.data
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus Unit',
                    text: 'Failed Load Syllabus!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },
        async patchPoint(){
            var data ={
                _method: 'patch',
                id: this.idPoint,
                content: this.point.content,
                cycle: this.point.cycle,
                description: this.point.description
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/syllabus/point`, data,  {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus Unit',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'SyllabusCoursesAdmin', params: { idCourse: this.paramsId } })
            }).catch(() => {
                this.$swal({
                    toast: true,
                    title: 'Syllabus Unit',
                    text: 'Failed Create New Syllabus Unit!',
                    icon: 'error',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
            })
        },
    }
}
</script>